import {
    AppBar,
    Box,
    Container,
    CssBaseline,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Notification} from "./Notification";
import {ProfileComponent} from "../features/profile/ProfileComponent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {Link, Outlet, useNavigate} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SportsIcon from "@mui/icons-material/Sports";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import HelpIcon from "@mui/icons-material/Help";
import EventNoteIcon from "@mui/icons-material/EventNote";
import React, {useState} from "react";
import {useAppSelector} from "../app/hooks";
import {selectAccount, selectProfile} from "../features/account/accountSlice";
import {AccountRoleClientDto} from "../api/NswagClient";

export function MainLayout() {

    const navigate = useNavigate();
    const account = useAppSelector(selectAccount);
    const profile = useAppSelector(selectProfile);

    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleSignin = () => {
        navigate('/account/signin');
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline/>
            <AppBar position='absolute'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={toggleDrawer}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >

                    </Typography>

                    <Notification/>

                    {
                        account && profile && <ProfileComponent account={account} profile={profile}/>
                    }

                    {
                        !account &&
                        <IconButton color="inherit" onClick={handleSignin}>
                            <AccountCircleIcon/>
                        </IconButton>
                    }

                </Toolbar>
            </AppBar>
            <Drawer
                open={open}
                onClose={toggleDrawer}
            >
                <Box sx={{ width: 250 }}>
                    <List>

                        <ListSubheader>Теннис</ListSubheader>

                        <ListItem disablePadding>
                            <ListItemButton onClick={toggleDrawer} component={Link} to='/'>
                                <ListItemIcon>
                                    <HomeIcon/>
                                </ListItemIcon>
                                <ListItemText primary='Главная'/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={toggleDrawer} component={Link} to='/contests'>
                                <ListItemIcon>
                                    <SportsTennisIcon/>
                                </ListItemIcon>
                                <ListItemText primary='Турниры'/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={toggleDrawer} component={Link} to='/coaches'>
                                <ListItemIcon>
                                    <SportsIcon/>
                                </ListItemIcon>
                                <ListItemText primary='Тренеры'/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={toggleDrawer} component={Link} to='/players'>
                                <ListItemIcon>
                                    <SportsMartialArtsIcon/>
                                </ListItemIcon>
                                <ListItemText primary='Игроки'/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={toggleDrawer} component={Link} to='/gyms'>
                                <ListItemIcon>
                                    <TableRestaurantIcon/>
                                </ListItemIcon>
                                <ListItemText primary='Площадки'/>
                            </ListItemButton>
                        </ListItem>

                        <ListSubheader>Помощь</ListSubheader>

                        <ListItem disablePadding>
                            <ListItemButton onClick={toggleDrawer} component={Link} to='/about'>
                                <ListItemIcon>
                                    <HelpIcon/>
                                </ListItemIcon>
                                <ListItemText primary='О сервисе'/>
                            </ListItemButton>
                        </ListItem>

                        {
                            account?.role === AccountRoleClientDto.Admin &&
                            <>
                                <ListSubheader>Администрирование</ListSubheader>

                                <ListItem disablePadding>
                                    <ListItemButton onClick={toggleDrawer} component={Link} to='/admin'>
                                        <ListItemIcon>
                                            <EventNoteIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary='Управление'/>
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton onClick={toggleDrawer} component={Link} to='/admin/journals'>
                                        <ListItemIcon>
                                            <EventNoteIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary='Журнал'/>
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton onClick={toggleDrawer} component={Link} to='/admin/users'>
                                        <ListItemIcon>
                                            <EventNoteIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary='Пользователи'/>
                                    </ListItemButton>
                                </ListItem>
                            </>
                        }

                        <ListSubheader>Социальные сети</ListSubheader>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, pl: 2, pr: 2, pt: 1, pb: 1 }}>
                            <TelegramIcon/>
                            <VkIcon/>
                        </Box>
                    </List>
                </Box>
            </Drawer>

            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Container sx={{ mt: 1, mb: 1, pl: 1, pr: 1 }}>
                    <Outlet/>
                </Container>
            </Box>
        </Box>
    );
}

function TelegramIcon() {
    return (
        <a href={'https://t.me/pinspinru'} target={'_blank'} rel={'noopener noreferrer'}>
            <img src={'/telegram.svg'}
                 height={32}
                 width={32}
                 alt={''}
                 style={{ filter: 'invert(40%) sepia(99%) saturate(2960%) hue-rotate(173deg) brightness(94%) contrast(101%)' }}/>
        </a>
    );
}

function VkIcon() {
    return (
        <a href={'https://vk.com/pinspinru'} target={'_blank'} rel={'noopener noreferrer'}>
            <img src={'/vk.svg'}
                 height={32}
                 width={32}
                 alt={''}
                 style={{ filter: 'invert(40%) sepia(99%) saturate(2960%) hue-rotate(173deg) brightness(94%) contrast(101%)' }}/>
        </a>
    );
}