import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {addDays, getHours, getMinutes, setHours, setMinutes} from "date-fns";
import {Alert, Autocomplete, Box, FormControl, TextField, Typography} from "@mui/material";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import {LoadingButton} from "@mui/lab";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {PinspinClient} from "../../api/PinspinClient";
import {CityClientDto, ContestClientDto, ContestUpdateClientDto} from "../../api/NswagClient";

export function ContestEditPage() {

    const params = useParams<{ id: string }>();
    const [contest, setContest] = useState<ContestClientDto>();
    const [cities, setCities] = useState<CityClientDto[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        (async () => {
            try {
                if (!params.id) {
                    return;
                }

                setLoading(true);

                const contest = await new PinspinClient().getContest(parseInt(params.id));
                setContest(contest);

                const citiesResult = await new PinspinClient().getCities(0, 1000);
                setCities(citiesResult.items!);
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        })()
    }, [params.id]);

    return (
        <>
            {
                contest && cities &&
                <ContestEditForm contest={contest} cities={cities}/>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>
    );
}

function ContestEditForm(props: { contest: ContestClientDto, cities: CityClientDto[] }) {

    const contest = props.contest;
    const cities = props.cities;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<any>();

    const formik = useFormik<ContestEditFormValues>({
        initialValues: {
            title: contest.name!.title!,
            description: contest.description!,
            locationId: contest.locationId!,
            address: contest.address!,
            date: contest.dueDate!,
            time: contest.dueDate!,
            maxParticipants: contest.restrictions!.maxParticipants!,
            maxRating: contest.restrictions?.maxRating ?? '',
            minRating: contest.restrictions?.minRating ?? '',
        },
        validate: values => {
            const errors = {};
            if (values.title.trim().length === 0) {
                Object.assign(errors, { title: 'Не должно быть пустым' });
            }
            if (values.description.trim().length === 0) {
                Object.assign(errors, { description: 'Не должно быть пустым' });
            }
            if (!values.locationId || values.locationId <= 0) {
                Object.assign(errors, { cityId: 'Необходимо выбрать' });
            }
            if (values.address.trim().length === 0) {
                Object.assign(errors, { address: 'Не должно быть пустым' });
            }
            if (!values.date) {
                Object.assign(errors, { date: 'Необходимо выбрать' });
            }
            if (!values.time) {
                Object.assign(errors, { time: 'Необходимо выбрать' });
            }
            if (values.maxParticipants < 2) {
                Object.assign(errors, { maxParticipants: 'Количество участников должно быть больше или равно 2' });
            }
            if (values.minRating !== '' && values.minRating <= 0) {
                Object.assign(errors, { minRating: 'Значение должно быть больше нуля' });
            }
            if (values.maxRating !== '' && values.maxRating <= 0) {
                Object.assign(errors, { maxRating: 'Значение должно быть больше нуля' });
            }
            if (values.minRating !== '' && values.maxRating !== '' && values.minRating > values.maxRating) {
                Object.assign(errors, { maxRating: 'Значение должно быть больше минимального рейтинга' });
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {
                setError(null);
                await new PinspinClient().updateContest(contest.contestId!, new ContestUpdateClientDto({
                    title: values.title.trim(),
                    description: values.description.trim(),
                    locationId: values.locationId,
                    address: values.address.trim(),
                    date: setHours(setMinutes(values.date, getMinutes(values.time)), getHours(values.time)),
                    maxParticipants: values.maxParticipants,
                    maxRating: values.maxRating ? Number(values.maxRating) : undefined,
                    minRating: values.minRating ? Number(values.minRating) : undefined,
                }));
                enqueueSnackbar('Турнир обновлен', { variant: "success" });
                navigate(`/contests/${contest.contestId}`);
            } catch (e) {
                setError(e);
            }
        }
    });

    const cityNames = new Map((cities ?? []).map(x => [x.cityId, x.name]));
    const cityIds = (cities ?? []).sort((a: CityClientDto, b: CityClientDto) => a.name! < b.name! ? -1 : 1).map(x => x.cityId);

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2 }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}>
                <Typography component="h1" variant="h5">
                    Новый турнир
                </Typography>

                <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 2 }}>

                    {
                        error &&
                        <Alert sx={{ mb: 2 }} severity='error'>
                            {ErrorFormatter.format(error)}
                        </Alert>
                    }

                    <Typography variant='body2' sx={{ mt: 1, mb: 1 }}>Описание</Typography>

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Название"
                        name="title"
                        placeholder='Например Кубок вызова'
                        inputProps={{ maxLength: 50 }}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Описание"
                        name="description"
                        placeholder='Короткое описание, условия проведения'
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 200 }}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                    />

                    <Typography variant='body2' sx={{ mt: 3 }}>Место и время</Typography>

                    <Autocomplete
                        sx={{ mt: 3 }}
                        fullWidth
                        id='locationId'
                        options={cityIds}
                        getOptionLabel={x => cityNames.get(x) ?? '-'}
                        noOptionsText='Ничего не найдено'
                        value={formik.values.locationId || null}
                        onChange={(e, v) => formik.setFieldValue('locationId', v)}
                        renderInput={(params) => <TextField {...params} required name="locationId" label='Локация'
                                                            placeholder='Локация проведения турнира'
                                                            error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                                                            helperText={formik.touched.locationId && formik.errors.locationId}/>}
                    />

                    <TextField
                        sx={{ mt: 3 }}
                        margin="normal"
                        required
                        fullWidth
                        label="Адрес"
                        name="address"
                        placeholder='Адрес или название места'
                        inputProps={{ maxLength: 50 }}
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                    />

                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <DatePicker
                                name="date"
                                disablePast
                                label='Дата'
                                maxDate={addDays(new Date(), 30)}
                                value={formik.values.date}
                                onChange={(v) => formik.setFieldValue('date', v)}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <TimePicker
                                name="time"
                                label='Время'
                                minutesStep={5}
                                value={formik.values.time}
                                onChange={(v) => formik.setFieldValue('time', v)}
                            />
                        </FormControl>
                    </Box>

                    <Typography variant='body2' sx={{ mt: 3, mb: 1 }}>Ограничения</Typography>

                    <TextField
                        sx={{ mt: 2 }}
                        margin="normal"
                        required
                        fullWidth
                        label="Максимальное количество участников"
                        name="maxParticipants"
                        placeholder='Максимальное количество участников'
                        type="number"
                        value={formik.values.maxParticipants}
                        onChange={formik.handleChange}
                        error={formik.touched.maxParticipants && Boolean(formik.errors.maxParticipants)}
                        helperText={formik.touched.maxParticipants && formik.errors.maxParticipants}
                    />

                    <TextField
                        sx={{ mt: 2 }}
                        margin="normal"
                        fullWidth
                        label="Минимальный рейтинг участника"
                        name="minRating"
                        placeholder='Минимальный рейтинг участника'
                        type="number"
                        value={formik.values.minRating}
                        onChange={formik.handleChange}
                        error={formik.touched.minRating && Boolean(formik.errors.minRating)}
                        helperText={(formik.touched.minRating && formik.errors.minRating) || 'не обязательно'}
                    />

                    <TextField
                        sx={{ mt: 2 }}
                        margin="normal"
                        fullWidth
                        label="Максимальный рейтинг участника"
                        name="maxRating"
                        placeholder='Максимальный рейтинг участника'
                        type="number"
                        value={formik.values.maxRating}
                        onChange={formik.handleChange}
                        error={formik.touched.maxRating && Boolean(formik.errors.maxRating)}
                        helperText={(formik.touched.maxRating && formik.errors.maxRating) || 'не обязательно'}
                    />

                    <LoadingButton
                        sx={{ mt: 3, mb: 2 }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={formik.isSubmitting}
                    >
                        Сохранить
                    </LoadingButton>

                </Box>
            </Box>
        </Box>
    );
}

interface ContestEditFormValues {
    title: string;
    description: string;
    locationId: number;
    address: string;
    date: Date;
    time: Date;
    maxParticipants: number;
    maxRating: number | string;
    minRating: number | string;
}