import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {GymFormatter} from "./GymFormatter";
import {PinspinClient} from "../../api/PinspinClient";
import {GymInfoClientDto} from "../../api/NswagClient";
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";

export function GymListPage() {

    const cities = useSelector(selectCities);
    const [gyms, setGyms] = useState<GymInfoClientDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getGyms(0,50)
            .then(x => setGyms(x.items!))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, []);

    const citiesMap = new Map(cities.map(x => [x.cityId, x.name]));

    const formatLocation = (gym: GymInfoClientDto) => {
        if (gym.locationId && gym.locationId > 0 && citiesMap.has(gym.locationId)) {
            return citiesMap.get(gym.locationId);
        }
        return '';
    };

    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>

            {
                !loading && !error &&
                <List>
                    {
                        gyms.map(x =>
                            <ListItem key={x.username} disableGutters disablePadding>
                                <ListItemButton component={Link} to={`/gyms/${x.username}`}>
                                    <ListItemAvatar>
                                        <Avatar alt={x.name} src={x.avatar}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={GymFormatter.formatName(x)}
                                                  secondary={formatLocation(x)}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                </List>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </Box>
    );
}