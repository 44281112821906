import {Alert, Autocomplete, Box, TextField, Typography} from "@mui/material";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {LoadingButton} from "@mui/lab";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useAppDispatch} from "../../app/hooks";
import {useSnackbar} from "notistack";
import {login} from "../account/accountSlice";
import {AvatarUploader} from "./AvatarUploader";
import {CityClientDto, GymProfileClientDto, UpdateGymProfileClientDto} from "../../api/NswagClient";
import {PinspinClient} from "../../api/PinspinClient";

export function GymProfileEdit(props: { profile: GymProfileClientDto }) {

    const dispatcher = useAppDispatch();
    const profile = props.profile;
    const { enqueueSnackbar } = useSnackbar();
    const [cities, setCities] = useState<CityClientDto[]>();
    const [error, setError] = useState<any>();

    useEffect(() => {
        (async () => {
            try {
                const citiesResult = await new PinspinClient().getCities(0, 1000);
                setCities(citiesResult.items!);
            } catch (e) {
                setError(e);
            }
        })()
    }, []);

    const formik = useFormik<ProfileEditForm>({
        initialValues: {
            name: profile.name ?? '',
            address: profile.address ?? '',
            locationId: profile.locationId!
        },
        validate: values => {
            const errors = {};
            if (values.name.trim().length === 0) {
                Object.assign(errors, { firstName: 'Не должно быть пустым' });
            }
            if (values.address.trim().length === 0) {
                Object.assign(errors, { lastName: 'Не должно быть пустым' });
            }
            if (!values.locationId || values.locationId <= 0) {
                Object.assign(errors, { locationId: 'Необходимо выбрать' });
            }
            return errors;
        },
        onSubmit: async (values, fmk) => {
            try {
                setError(null);
                await new PinspinClient().updateGymProfile(profile.gymId!, new UpdateGymProfileClientDto({
                    name: values.name,
                    address: values.address,
                    locationId: values.locationId
                }));
                fmk.resetForm({ values: values });
                dispatcher(login());
                enqueueSnackbar('Профиль обновлен', { variant: "success" });
            } catch (e) {
                setError(e);
            }
        }
    });

    const handleUploadFinished = () => {
        dispatcher(login());
        enqueueSnackbar('Аватар обновлен', { variant: "success" });
    };

    const handleUploadError = (error: string) => {
        enqueueSnackbar(error, { variant: "error" });
    };

    const cityNames = new Map((cities ?? []).map(x => [x.cityId, x.name]));
    const cityIds = (cities ?? []).sort((a: CityClientDto, b: CityClientDto) => a.name! < b.name! ? -1 : 1).map(x => x.cityId);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }}>

            <Typography component="h1" variant="h5">
                Редактирование профиля
            </Typography>

            <AvatarUploader
                avatar={profile.avatar!}
                url={`/api/gym/${profile.gymId}/avatar`}
                onFinish={handleUploadFinished}
                onError={handleUploadError}
            />

            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 2 }}>

                {
                    error &&
                    <Alert sx={{ mb: 2 }} severity='error'>
                        {ErrorFormatter.format(error)}
                    </Alert>
                }

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Название"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />

                <Autocomplete
                    sx={{ mt: 2 }}
                    fullWidth
                    id='locationId'
                    options={cityIds}
                    getOptionLabel={x => cityNames.get(x) ?? '-'}
                    noOptionsText='Ничего не найдено'
                    value={formik.values.locationId || null}
                    onChange={(e, v) => formik.setFieldValue('locationId', v)}
                    renderInput={(params) => <TextField {...params} name="locationId" label='Локация'
                                                        error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                                                        helperText={formik.touched.locationId && formik.errors.locationId}/>}
                />

                <TextField
                    sx={{ mt: 3 }}
                    margin="normal"
                    required
                    fullWidth
                    label="Адрес"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                />

                <LoadingButton
                    sx={{ mt: 3, mb: 2 }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={formik.isSubmitting}
                    disabled={!formik.dirty}
                >
                    Сохранить
                </LoadingButton>
            </Box>

        </Box>
    );
}

interface ProfileEditForm {
    name: string;
    address: string;
    locationId: number;
}