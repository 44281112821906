import {Link} from "react-router-dom";
import {Box, Typography} from "@mui/material";

export function AboutPage() {

    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper', p: 2}}>

            <Typography component="h1" variant="h5">О сервисе</Typography>

            <Box sx={{mt: 2}}>
                <Typography variant='body1'>
                    Сервис создан для популяризации настольного тенниса.
                    Проведения турниров, поиска партнера для игры, тренера для персональных и групповых
                    тренировок, зала для игры.
                </Typography>
            </Box>

            <Box sx={{mt: 2}}>
                <Typography variant='body1'>
                    Полезные ссылки
                </Typography>
            </Box>

            <ul>
                <li>
                    <Link to='/rules'><Typography variant='body1'>Правила сервиса</Typography></Link>
                </li>
                <li>
                    <Link to='/faq'><Typography variant='body1'>Часто задаваемые вопросы</Typography></Link>
                </li>
                <li>
                    <Link to='/eula'><Typography variant='body1'>Пользовательское соглашение</Typography></Link>
                </li>
            </ul>
        </Box>
    );
}