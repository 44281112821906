import React from "react";
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {ContestClientDto, ContestFormatTypeClientDto} from "../../../api/NswagClient";

export interface FormatSettings {
    groupsCount: number,
    winsCount: number,
    roundsCount: number
}

export function FormatSettingsStep(props: {
    contest: ContestClientDto,
    settings: FormatSettings,
    formatType: ContestFormatTypeClientDto,
    onChange: (settings: FormatSettings) => void
}) {

    const maxRoundsCount = 5;
    const roundsCountOptions = Array.from(Array(maxRoundsCount).keys()).map(x => x + 1);

    const maxWinsCount = 5;
    const winsCountOptions = Array.from(Array(maxWinsCount).keys()).map(x => x + 1);

    return (
        <Box sx={{ p: 2 }}>

            <Box>
                <Typography variant='h5'>Настройки</Typography>
            </Box>

            {
                props.formatType === ContestFormatTypeClientDto.RoundRobin &&

                <Box sx={{ mt: 3 }}>
                    <FormControl fullWidth>
                        <InputLabel id="groups-count-label">Количество групп</InputLabel>
                        <Select
                            labelId="groups-count-label"
                            id="groups-count-select"
                            value={props.settings.groupsCount}
                            label="Количество групп"
                            onChange={x => {
                                props.onChange({ ...props.settings, groupsCount: Number(x.target.value) })
                            }}
                        >
                            {
                                roundsCountOptions.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText>Количество групп, на которые делятся участники, матчи проводятся внутри
                            групп</FormHelperText>
                    </FormControl>
                </Box>
            }

            <Box sx={{ mt: 3 }}>
                <FormControl fullWidth>
                    <InputLabel id="wins-count-label">Количество побед</InputLabel>
                    <Select
                        labelId="wins-count-label"
                        id="wins-count-select"
                        value={props.settings.winsCount}
                        label="Количество побед"
                        onChange={x => {
                            props.onChange({ ...props.settings, winsCount: Number(x.target.value) })
                        }}
                    >
                        {
                            winsCountOptions.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText>Количество побед, необходимое для определения победителя в матче</FormHelperText>
                </FormControl>
            </Box>

        </Box>
    );
}