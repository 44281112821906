import {useEffect, useState} from "react";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import {Box, Button, Link, List, ListItem, ListItemButton, Paper, Typography} from "@mui/material";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";
import {PinspinClient} from "../../api/PinspinClient";
import {ContestInfoClientDto} from "../../api/NswagClient";
import {selectAccount} from "../account/accountSlice";
import {ContestListItem} from "./ContestListItem";

export function ContestListPage() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const account = useSelector(selectAccount);
    const cities = useSelector(selectCities);
    const [contests, setContests] = useState<ContestInfoClientDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const filter = searchParams.get('filter');

    useEffect(() => {
        setLoading(true);
        const client = new PinspinClient();
        const promise = filter === 'organizer'
            ? client.getOrganizerContests(0, 100)
            : filter === 'player'
                ? client.getPlayerContests(0, 100)
                : client.getContests(0, 100);
        promise
            .then(x => setContests(x.items!))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [filter]);

    const handleAddClick = () => {
        navigate('/contests/create');
    };

    const cityNames = new Map((cities ?? []).map(x => [x.cityId!, x.name!]));

    return (
        <Box>
            <Paper elevation={0}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 2,
                    pt: 1,
                    pb: 1,
                    pl: 2,
                    pr: 2
                }}>
                    {
                        account &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            {
                                filter === 'organizer' || filter === 'player'
                                    ? <Link to={'/contests'}
                                            underline="none"
                                            component={RouterLink}
                                            sx={{ fontSize: 14 }}
                                    >
                                        все
                                    </Link>
                                    : <Typography sx={{ fontSize: 14 }}>все</Typography>
                            }
                            {
                                filter !== 'organizer'
                                    ?
                                    <Link to={'/contests?filter=organizer'}
                                          underline="none"
                                          component={RouterLink}
                                          sx={{ fontSize: 14 }}
                                    >
                                        организую
                                    </Link>
                                    : <Typography sx={{ fontSize: 14 }}>организую</Typography>
                            }
                            {
                                filter !== 'player'
                                    ?
                                    <Link to={'/contests?filter=player'}
                                          underline="none"
                                          component={RouterLink}
                                          sx={{ fontSize: 14 }}
                                    >
                                        участвую
                                    </Link>
                                    : <Typography sx={{ fontSize: 14 }}>участвую</Typography>
                            }
                        </Box>
                    }
                    <Box sx={{ flexGrow: 1 }}/>
                    <Button variant='text' onClick={handleAddClick} size='small'>Добавить</Button>
                </Box>
            </Paper>

            <Paper elevation={0} sx={{ mt: 1 }}>

                {
                    !loading && !error && contests.length > 0 &&
                    <>
                        <List>
                            {
                                contests.map(x =>
                                    <ListItem key={x.contestId} disableGutters disablePadding>
                                        <ListItemButton component={RouterLink} to={`/contests/${x.contestId}`}>
                                            <ContestListItem contest={x} cityNames={cityNames} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                        </List>
                    </>
                }

                {
                    !loading && !error && contests.length === 0 &&
                    <Box sx={{ p: 2 }}>
                        <Typography>Пока нет ни одного турнира</Typography>
                    </Box>
                }

                {
                    loading && <LoadingComponent/>
                }

                {
                    error && <ErrorComponent error={error}/>
                }
            </Paper>
        </Box>
    );
}

