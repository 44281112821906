import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Avatar, Box, Paper, Typography} from "@mui/material";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {GymFormatter} from "./GymFormatter";
import {PinspinClient} from "../../api/PinspinClient";
import {GymClientDto} from "../../api/NswagClient";
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";
import {TitleComponent} from "../common/TitleComponent";

export function GymPage() {

    const params = useParams<{ username: string }>();
    const cities = useSelector(selectCities);
    const [gym, setGym] = useState<GymClientDto>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getGym(params.username ?? '')
            .then(x => setGym(x))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [params.username]);

    const citiesMap = new Map(cities.map(x => [x.cityId, x.name]));

    const formatLocation = (gym: GymClientDto) => {
        if (!gym.locationId) {
            return '';
        }

        return `${citiesMap.get(gym.locationId!) ?? ''}, ${gym.address}`
    };

    return (
        <>
            {
                !loading && !error && gym &&
                <>
                    <Paper elevation={0} sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Avatar src={gym.avatar} sx={{ width: 56, height: 56 }} variant='circular'/>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <TitleComponent>
                                    {GymFormatter.formatName(gym)}
                                </TitleComponent>
                                <Typography sx={{ color: 'text.secondary' }}>
                                    {formatLocation(gym)}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>
    );
}