import React, {useState} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {ContestClientDto} from "../../../api/NswagClient";
import {useSelector} from "react-redux";
import {selectAccount} from "../../account/accountSlice";
import {useSnackbar} from "notistack";
import {PinspinClient} from "../../../api/PinspinClient";
import {ErrorFormatter} from "../../common/ErrorFormatter";
import {Box, Paper, Typography} from "@mui/material";
import {ContestUtils} from "../ContestUtils";
import {LoadingButton} from "@mui/lab";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export function ContestParticipantActions(props: {
    contest: ContestClientDto,
    onChanged: (contest: ContestClientDto) => void
}) {

    const account = useSelector(selectAccount);
    const contest = props.contest;
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [joining, setJoining] = useState(false);
    const [leaving, setLeaving] = useState(false);

    const handleJoin = async () => {
        try {
            if (!contest) {
                return;
            }
            setJoining(true);
            const contestService = new PinspinClient();
            await contestService.joinContest(contest.contestId!);
            props.onChanged(await contestService.getContest(contest.contestId!));
            enqueueSnackbar('Заявка отправлена', { variant: "success" });
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setJoining(false);
        }
    };

    const handleLeave = async () => {
        try {
            if (!contest) {
                return;
            }
            setLeaving(true);
            const contestService = new PinspinClient();
            await contestService.leaveContest(contest.contestId!);
            props.onChanged(await contestService.getContest(contest.contestId!));
            enqueueSnackbar('Заявка отозвана', { variant: "success" });
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setLeaving(false);
        }
    };

    return (
        <Paper elevation={0} sx={{ mt: 1, p: 2 }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mt: 1 }}>

                {
                    contest.participants?.every(x => x.playerId !== account?.userId) &&
                    <>
                        <ErrorOutlineOutlinedIcon color='info'/>
                        <Typography>Вы не участвуете</Typography>
                    </>
                }
                {
                    ContestUtils.isPendingJoin(account, contest) &&
                    <>
                        <PendingOutlinedIcon color='info'/>
                        <Typography>Заявка отправлена</Typography>
                    </>
                }
                {
                    ContestUtils.isRejected(account, contest) &&
                    <>
                        <CancelOutlinedIcon color='error'/>
                        <Typography>Заявка отклонена</Typography>
                    </>
                }
                {
                    ContestUtils.isAccepted(account, contest) &&
                    <>
                        <CheckCircleOutlineIcon color='success'/>
                        <Typography>Вы участвуете</Typography>
                    </>
                }
                {
                    ContestUtils.isReserved(account, contest) &&
                    <>
                        <ChangeCircleOutlinedIcon color='warning'/>
                        <Typography>Вы в резерве</Typography>
                    </>
                }

                <Box sx={{ flexGrow: 1 }}></Box>

                {
                    ContestUtils.canLeave(account, contest) &&
                    <LoadingButton size="small" onClick={handleLeave}
                                   loading={leaving}>Не участвовать</LoadingButton>
                }

                {
                    ContestUtils.canJoin(account, contest) &&
                    <LoadingButton size="small" onClick={handleJoin}
                                   loading={joining}>Участвовать</LoadingButton>
                }

                {
                    !account &&
                    <LoadingButton size="small" component={RouterLink} to='/account/signin'
                                   state={{ from: location }}>Участвовать</LoadingButton>
                }
            </Box>
        </Paper>
    )
}