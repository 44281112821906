import {ContestInfoClientDto} from "../../api/NswagClient";
import {Box, Typography} from "@mui/material";
import {format} from "date-fns";
import {ru} from "date-fns/locale";

export function ContestListItem(props: { contest: ContestInfoClientDto, cityNames: Map<number, string> }) {

    const contest = props.contest;
    const cityNames = props.cityNames;

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                gap: 1
            }}>

                <Typography sx={{ color: 'text.primary', fontSize: 16 }} noWrap>
                    {contest.name?.title}
                </Typography>

                {
                    contest.restrictions?.minRating &&
                    <Typography sx={{
                        fontSize: 12,
                        color: 'white',
                        backgroundColor: 'info.main',
                        borderRadius: 0.5,
                        pl: 0.5,
                        pr: 0.5
                    }}>{contest.restrictions.minRating}</Typography>
                }
                {
                    contest.restrictions?.maxRating &&
                    <Typography sx={{
                        fontSize: 12,
                        color: 'white',
                        backgroundColor: 'error.main',
                        borderRadius: 0.5,
                        pl: 0.5,
                        pr: 0.5
                    }}>{contest.restrictions.maxRating}</Typography>
                }
            </Box>

            <Typography sx={{ color: 'text.secondary', fontSize: 14 }} noWrap>
                {(cityNames.get(contest.locationId!) ?? '') + ', ' + contest.address}
            </Typography>

            <Typography sx={{ color: 'text.secondary', fontSize: 14 }} noWrap>
                {format(contest.dueDate!, 'd MMMM, HH:mm', { locale: ru })}
            </Typography>

        </Box>
    );
}